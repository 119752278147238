import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header border-0 pt-6" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "svg-icon svg-icon-1 position-absolute ms-6" }
const _hoisted_5 = { class: "card-toolbar" }
const _hoisted_6 = {
  key: 0,
  class: "d-flex justify-content-end",
  "data-kt-customer-table-toolbar": "base"
}
const _hoisted_7 = {
  type: "button",
  class: "btn btn-primary"
}
const _hoisted_8 = { class: "svg-icon svg-icon-2" }
const _hoisted_9 = {
  key: 0,
  class: "card-body pt-0"
}
const _hoisted_10 = { class: "symbol symbol-60px" }
const _hoisted_11 = ["alt", "src"]
const _hoisted_12 = {
  href: "#",
  class: "btn btn-sm btn-light btn-active-light-primary",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-placement": "bottom-end",
  "data-kt-menu-flip": "top-end"
}
const _hoisted_13 = { class: "svg-icon svg-icon-5 m-0" }
const _hoisted_14 = {
  class: "menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4",
  "data-kt-menu": "true"
}
const _hoisted_15 = { class: "menu-item px-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Datatable = _resolveComponent("Datatable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("form", {
          onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.searchItems && _ctx.searchItems(...args)), ["prevent"])),
          class: "d-flex align-items-center position-relative my-1"
        }, [
          _createElementVNode("span", _hoisted_4, [
            _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen021.svg" })
          ]),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            placeholder: "Search",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pagingFilteration.filter.searchTerm) = $event)),
            class: "form-control form-control-solid w-250px ps-15"
          }, null, 512), [
            [_vModelText, _ctx.pagingFilteration.filter.searchTerm]
          ])
        ], 32)
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_ctx.checkedMedia.length === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("button", _hoisted_7, [
                _createVNode(_component_router_link, {
                  to: `/cmsmedia/add`,
                  class: "menu-link px-3 text-white"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_8, [
                      _createVNode(_component_inline_svg, { src: "media/icons/duotune/Navigation/Plus.svg" })
                    ]),
                    _createTextVNode(" Add Media")
                  ]),
                  _: 1
                })
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    (_ctx.tableData.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createVNode(_component_Datatable, {
            loading: _ctx.loading,
            "table-data": _ctx.tableData,
            "table-header": _ctx.tableHeader,
            onCurrentChange: _ctx.onCurrentChange,
            total: _ctx.paginationHeader?.TotalCount,
            "enable-items-per-page-dropdown": true,
            onItemsPerPageChange: _ctx.onItemsPerPageChange,
            rowsPerPage: _ctx.pagingFilteration.paging.pageSize
          }, {
            "cell-url": _withCtx(({ row: media }) => [
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("img", {
                  alt: media.name,
                  src: media.url,
                  class: "object-fit-cover"
                }, null, 8, _hoisted_11)
              ])
            ]),
            "cell-name": _withCtx(({ row: media }) => [
              _createVNode(_component_router_link, {
                to: `/cmsmedia/details/${media.id}`,
                class: "text-gray-600 text-hover-primary"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(media.name), 1)
                ]),
                _: 2
              }, 1032, ["to"])
            ]),
            "cell-id": _withCtx(({ row: media }) => [
              _createTextVNode(_toDisplayString(media.id), 1)
            ]),
            "cell-createdDateTime": _withCtx(({ row: media }) => [
              _createTextVNode(_toDisplayString(_ctx.moment(new Date(media.createdDateTime)).format('DD MMM YYYY')), 1)
            ]),
            "cell-actions": _withCtx(({ row: media }) => [
              _createElementVNode("a", _hoisted_12, [
                _createTextVNode("Actions "),
                _createElementVNode("span", _hoisted_13, [
                  _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr072.svg" })
                ])
              ]),
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("div", _hoisted_15, [
                  _createVNode(_component_router_link, {
                    to: `/cmsmedia/details/${media.id}`,
                    class: "menu-link px-3"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("View")
                    ]),
                    _: 2
                  }, 1032, ["to"])
                ])
              ])
            ]),
            _: 1
          }, 8, ["loading", "table-data", "table-header", "onCurrentChange", "total", "onItemsPerPageChange", "rowsPerPage"])
        ]))
      : _createCommentVNode("", true)
  ]))
}