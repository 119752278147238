
import { defineComponent, ref, onMounted, watch } from 'vue';
import ApiService from '@/core/services/ApiService';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
import { IMedia } from '@/core/data/media';
import { reinitializeComponents } from '@/core/plugins/keenthemes';
import moment from 'moment';

export default defineComponent({
	name: 'users-listing',
	components: {
		Datatable
	},
	setup() {
		const loading = ref(true);
		const tableData = ref<Array<IMedia>>([]);
		const initMedia = ref<Array<IMedia>>([]);
		const paginationHeader = ref<any>({ TotalCount: 0 });
		const pagingFilteration = ref<any>({
			paging: {
				pageNumber: 1,
				pageSize: 10
			},
			filter: {
				searchTerm: ''
			},
			orderBy: {
				orderByField: '',
				orderDesc: false
			}
		});

		const checkedMedia = ref([]);
		const tableHeader = ref([
			{
				name: 'Image',
				key: 'url',
				sortable: true
			},
			{
				key: 'checkbox',
				sortable: false
			},
			{
				name: 'Title',
				key: 'name',
				sortable: true
			},
			{
				name: 'Id',
				key: 'id',
				sortable: true
			},
			{
				name: 'created At',
				key: 'createdDateTime',
				sortable: true
			},
			{
				name: 'Actions',
				key: 'actions'
			}
		]);

		watch(
			() => pagingFilteration.value.paging.pageSize,
			(newValue, oldValue) => {
				getMediaList();
			},
			{ deep: true }
		);

		watch(
			() => pagingFilteration.value.paging.pageNumber,
			(newValue, oldValue) => {
				getMediaList();
			},
			{ deep: true }
		);

		const onItemsPerPageChange = itemsPerPage => {
			// console.log('itemsPerPage: ', itemsPerPage);
			pagingFilteration.value.paging.pageSize = itemsPerPage;
		};

		const onCurrentChange = currentPage => {
			// console.log('current page: ', currentPage);
			pagingFilteration.value.paging.pageNumber = currentPage;
		};

		onMounted(() => {
			setCurrentPageBreadcrumbs('CMS Media', []);
			getMediaList();
		});

		const getMediaList = () => {
			ApiService.post(`CmsMedia/list`, pagingFilteration.value)
				.then(({ data, headers }) => {
					paginationHeader.value = JSON.parse(headers['x-pagination']);

					tableData.value.splice(0, tableData.value.length, ...data);
					initMedia.value.splice(0, tableData.value.length, ...data);
					loading.value = false;
					reinitializeComponents();
				})
				.catch(({ response }) => {
					console.log('response', response);
				});
		};

		// const search = ref<string>('');
		const searchItems = () => {
			getMediaList();

			// tableData.value.splice(0, tableData.value.length, ...initMedia.value);
			// if (search.value !== '') {
			// 	let results: Array<IMedia> = [];
			// 	for (let j = 0; j < tableData.value.length; j++) {
			// 		if (searchingFunc(tableData.value[j], search.value)) {
			// 			results.push(tableData.value[j]);
			// 		}
			// 	}
			// 	tableData.value.splice(0, tableData.value.length, ...results);
			// }
		};

		// const searchingFunc = (obj, value): boolean => {
		// 	for (let key in obj) {
		// 		if (
		// 			!Number.isInteger(obj[key]) &&
		// 			!(typeof obj[key] === 'object') &&
		// 			!(typeof obj[key] === 'boolean')
		// 		) {
		// 			if (obj[key].indexOf(value) != -1) {
		// 				return true;
		// 			}
		// 		}
		// 	}
		// 	return false;
		// };

		return {
			// search,
			moment,
			loading,
			tableData,
			tableHeader,
			searchItems,
			getMediaList,
			checkedMedia,
			onCurrentChange,
			paginationHeader,
			pagingFilteration,
			onItemsPerPageChange
		};
	}
});
